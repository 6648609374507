.visakort {
    max-height: 40px;
}

.flirt-steps {
    @apply px-2 mb-2 rounded;
}

.f-s-title {
    @apply px-2 py-2 mb-2 text-base rounded;
    display: flex;
    align-items: center;
    height: 56px;
    background-color: rgba(0, 0, 0, 0.5);
}

.f-s-option {
    @apply px-2 py-2 flex flex-row w-full mb-2 items-center justify-between rounded cursor-pointer;
    height: 56px;
    //color: rgb(200,200,200);
    background-color: rgba(0, 0, 0, 0.5);
}

.f-s-option.selected {
    color: white;
    @apply bg-green-500;
    border: 1px solid white;
}

//
//.f-s-option.selected .f-s-special {
//    font-weight: bold;
//}

.f-s-amount {
    @apply text-xl leading-none text-center;
    line-height: 0.85;
}


.f-s-value {
    @apply text-xl leading-none ;
    line-height: 0.85;
}

.f-s-description {
    @apply text-sm;
    line-height: 1.3;
}


.f-s-checkout {
    @apply text-sm;
}

.f-s-checkout-info {

}

.f-s-checkout-items {
    @apply py-1 ;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.f-s-checkout-button {
    @apply p-2 text-center w-full button-shadow-b ;
    font-weight: bold;
    color: white;
}


@screen md {
    .flirt-steps {
        @apply px-3;
    }

    .visakort {
        max-height: 80px;
    }

    .f-s-amount {
        @apply text-3xl;
    }

    .f-s-value {
        @apply text-3xl;
    }

    .f-s-title {
        height: 56px;
        min-width: 310px;
        @apply py-2 mb-2;
    }

    .f-s-checkout-items{
        @apply py-2;
    }
    .f-s-option {
        height: 56px;
        @apply py-2 mb-2;
        min-width: 310px;
    }
}


@screen sm{
    min-width:114px;
}

@screen lg{
    .payment-method-extra{
        min-width: 50px;
    }
}
@screen xl{
    .payment-method-extra{
        min-width: 114px;
    }
}
