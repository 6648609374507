.search-checkbox {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
}

.age-slider {
    @apply flex-1;
    min-width: 100px;
    margin-top: 2px;
}


.advanced-search {
    margin-left: auto;
    margin-right: auto;
    @apply text-left;
    justify-content: space-around;
}


.advanced-search-field {
    @apply flex flex-row mb-1 p-1;
}

.advanced-search-label {
    width: 140px;
    flex-grow: 1;
}

.advanced-search-select {
    @apply text-black;
    @apply p-1 ml-2;
    width: 100px;
}


.search-item {
    @apply px-1 mb-2 w-1/2 text-sm;
}

@screen sm {
    .search-item {
        @apply px-2 w-1/3;
    }
}


@screen md {
    .search-item {
        @apply text-base w-1/3 ;
    }

    .age-slider {
        max-width: 150px;
        min-width: 150px;
        margin-top: 2px;
    }
}

@screen lg {
    .search-item {
        @apply w-1/5;
    }

    .advanced-search {
        justify-content: space-between;
        width: 892px;
    }
}

@screen xl {
    .search-item {
        @apply mb-3 ;
    }
}
