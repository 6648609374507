.chat-form {
    @apply w-full p-1 rounded bg-white text-black;
    height: 56px;
}


@screen md {
    .chat-form {
        height: 112px;
    }
}
