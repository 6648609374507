@import './typography.scss';
@import "~sweetalert2/dist/sweetalert2.min.css";
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~material-design-iconic-font/dist/css/material-design-iconic-font.css';
@import '~vue-directive-tooltip/src/css/index.scss';
@import '~animate.css/animate.css';
@import 'glyphicons.css';
@import 'animations.css';
@import '~nprogress/nprogress.css';


img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

html{
  overflow: hidden;
}

html, body {
  height: 100%;
  background: #251f1f;
}


.bg-trans {
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.bg-trans-white {
  background-color: rgba(255, 255, 255, 0.2);

}

.v--modal-overlay .v--modal-box {
  background-color: rgba(0, 0, 0, 0.5);
}

.v--modal-overlay {
  background: transparent;
}

.text-shadow-a {
  text-shadow: 2px 2px 2px black;
}

.text-shadow-d-sm {
  text-shadow: 1px 1px 1px #333;
}

.button-shadow-a {
  background: linear-gradient(to bottom, #ee0000 0%, #6d0019 100%);
}

.button-text-shadow-a {
  background: linear-gradient(to bottom, #ee0000 0%, #6d0019 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-text-shadow-c {
  background: linear-gradient(to bottom, #ffffff 10%, #45392f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.button-shadow-b {
  background: linear-gradient(to bottom, #333333 0%, #000000 100%);
}

.date-box {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: white;
}

.date-box-a {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: white;
}


.date-container {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: white;
}

.chat-container {
  height: 100%;
}

.cookie-consent {
  @apply absolute w-full px-2 py-4 pb-10 z-50 bottom-0 text-center;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: white;
}

@screen md {
  .chat-container {
    height: auto;
  }

  .date-container {
    border-radius: 4px;
    @apply my-4;
  }

  .date-box-a {
    border-radius: 4px;
  }
}

.date-box-darker {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.6);
  color: white;

}

@import './dolphin/layout.scss';


@tailwind base;
@tailwind components;
@tailwind utilities;


/* CHECKBOX TOGGLE SWITCH */
.form-switch {
  @apply relative select-none w-12 mr-2 leading-normal;
}

.form-switch-checkbox {
  @apply hidden;
}

.form-switch-label {
  @apply block overflow-hidden cursor-pointer  border rounded-full h-6  shadow-inner;
  border: rgb(0, 0, 0);
  background-color: rgb(109, 27, 0);
  transition: background-color 0.2s ease-in;
}

.form-switch-label:before {
  @apply absolute block bg-white top-0  bottom-0 w-6 border rounded-full -ml-1;

  right: 50%;
  content: "";
  transition: all 0.2s ease-in;
}

.form-switch-checkbox:checked + .form-switch-label,
.form-switch-checkbox:checked + .form-switch-label:before {

}

.form-switch-checkbox:checked + .form-switch-label {
  @apply shadow-none;
  background: rgb(0, 109, 27);
}

.form-switch-checkbox:checked + .form-switch-label:before {
  @apply right-0;
}

select, input {
  max-width: 100%;
  outline-color: rgba(0, 0, 0, 0.50);
  @apply rounded;
}

img[lazy=loading] {
  filter: blur(20px);
}


textarea, button {
  outline-color: rgba(0, 0, 0, 0.50);
  @apply rounded;
}

:focus {
  outline: none;
}

button:focus, a:focus, {
  outline: 0px dotted;
  outline-color: rgba(250, 0, 0, 0.1);
}


@tailwind screens;

textarea {
  resize: none;
}

.break-words-a {
  word-break: break-word;
  overflow-wrap: break-word;
}


#scroller {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}


.fade-enter-active {
  transition: opacity 3s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

///* Track */
//::-webkit-scrollbar-track {
//    border-radius: 0px;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//    background: rgba(100, 50, 50, 0.7);
//
//}
//
//::-webkit-scrollbar-thumb:window-inactive {
//    background: rgba(100, 50, 50, 0.4);
//}
@screen md {
  ::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #484848d4;
    background-image: url('data:image/svg+xml;utf8,<svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>');
    background-repeat: no-repeat;
    background-position: center center;

  }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(116, 116, 116, 0.55);
    }


  ::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.2);
    width: 16px;
  }


  //::-webkit-scrollbar-button:single-button {
  //    background-size: 100%;
  //    height: 16px;
  //    padding:3px 0;
  //    width: 16px;
  //    -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
  //}
  //
  ///* Up */
  //::-webkit-scrollbar-button:single-button:vertical:decrement {
  //    background-image: url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(0,0,0,0.5)'><polygon points='0,50 100,50 50,0'/></svg>');
  //
  //}
  //
  //::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  //    background-image: url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(0,0,0,0.9)'><polygon points='0,50 100,50 50,0'/></svg>');
  //}
  //
  ///* Down */
  //::-webkit-scrollbar-button:single-button:vertical:increment {
  //    background-image: url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(0,0,0,0.5)'><polygon points='0,0 100,0 50,50'/></svg>');
  //}
  //
  //::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  //    background-image: url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(0,0,0,0.9)'><polygon points='0,0 100,0 50,50'/></svg>');
  //
  //}
}
.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */


}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.scroller-overlay {
  overflow-y: overlay;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroller-visible {
  overflow-y: auto;
}

@screen md {
  .scroller-visible {
    overflow-y: scroll;
  }
}

.chatter .scroller-visible {
  overflow-y: auto;
  overscroll-behavior: none;
}

@screen md {
  .chatter .scroller-visible {
    overflow-y: scroll;
  }
}

.scroller-hover {
  @extend .disable-scrollbars;
  overflow-y: scroll;
  overflow-y: overlay;
  -ms-overflow-style: -ms-autohiding-scrollbar; /* IE 10+ */
}

@screen md {
  .scroller-hover:hover, .scroller-hover:focus {
    scrollbar-width: 12px; /* Firefox */

  }

  .scroller-hover:hover::-webkit-scrollbar, .scroller-hover:focus::-webkit-scrollbar {
    width: 10px;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Chrome/Safari/Webkit */
  }
}

.scroller {
  overflow-y: scroll;
  overflow-x: hidden;
  @extend .disable-scrollbars;
}

.scroller-h {
  overflow-x: scroll;
  @extend .disable-scrollbars;
}


#scroll_sizer {
  min-height: 100%;
}

.nothing {
  display: none;
}

#app {
  background: url('/themes/D1/images/bg.jpg') no-repeat fixed;
  background-size: 300%;

  background-position: center top;
  overflow: hidden
}

.top-menu-logo {

}


@screen md {
  #app {
    background-size: cover;
    background-position: center center;
  }

  .g-recaptcha {
    transform: scale(0.85);
    transform-origin: 0 0;
  }

}

@screen lg {
  .top-menu-logo {
    margin-left: -26px;
  }
}

@import './dolphin/range-slider.css';
@import './dolphin/profile.scss';
@import './dolphin/flirts.scss';
@import './dolphin/search.scss';
@import './dolphin/chat.scss';
@import './dolphin/float-window.scss';


.message-counter {
  @apply bg-red-600 rounded absolute  -mt-5 text-base leading-none;
  padding: 1px;
  right: 0;
}

.message-counter-tc {
  @apply bg-red-600 rounded-full absolute  text-base leading-none;
  left: 100%;
  padding: 1px 5px 3px 4px;
  top: 0;
  min-width: 20px;
}


ul.uw-main {
  display: flex;

}

.uw-item {
  width: 80px;
  padding-right: 4px;
}


img.uw-images {
  display: block;
  border: 1px solid black;
  margin: auto;
}

/*** panel ***/
.panel {
  @apply rounded w-full mt-4 mb-1;
  border: 1px solid black
}

.panel-title {
  @apply py-1 px-2 ;
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid black;
}

.panel-content {

  @apply py-2 px-2 flex flex-wrap content-center;
}

/** online **/

.onlineIndicator {
  color: black;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
  background: rgb(149, 208, 143);
  background: linear-gradient(to bottom, rgb(138, 214, 149) 0%, rgb(149, 208, 143) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a9db80', endColorstr='#96c56f', GradientType=0);
  transition: padding 0.2s ease-out;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700;
  position: absolute;
  border-radius: 10px;
  padding: 1px 4px;
  top: 2px;
  left: 2px;
  @apply text-xs;
}

.onlineIndicator > span {
  margin-left: -62px;
  opacity: 0;
  transition: all 0.2s ease-in;
  display: none;
}

.onlineIndicator:hover {
  //padding: 2px 5px;
  transition: all 0.2s ease-out;
}

@screen md {
  .onlineIndicator {
    @apply text-sm;
    top: 5px;
    left: 5px;
    padding: 2px 5px;

  }
}
.onlineIndicator:hover > span {
  margin-left: 5px;
  opacity: 1;
  transition: all 0.2s ease-in;
}

.onlineIndicator .zmdi {
  font-weight: bold;
  transition: all 0.5s ease-in-out;
  animation: blink normal 1.5s infinite ease-in-out;
}


/*** Favorite ***/
.user-favorite {
  @apply text-sm;
}


.user-favorite-container:hover .user-favorite {
  border: 1px solid #ffffff;
}

.user-favorite-remove {
  @apply text-2xl;
  @apply rounded-full;
  @apply px-2;
  @apply py-1;
  line-height: 0;
  background: red;
  background: linear-gradient(to bottom, #ee0000 0%, #6d0019 100%);
  color: white;
  display: none;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 32px;
  height: 32px;
  z-index: 10;
}

.user-favorite-remove:focus {
  outline: black;
}

.user-favorite-container:hover .user-favorite-remove {
  display: block;
}

@screen md {
  .user-favorite {
    @apply text-base;
  }
}

/** Chat **/
.conversation-message {
  @apply px-1 flex-1 truncate  text-left;
}

.conversation-kiss {
  height: 24px;
}

.conversation-username {
  @apply px-1 truncate;
}

@screen md {
  .conversation-message {
    @apply px-2;
    min-width: 150px;
  }

  .conversation-username {
    @apply px-2;
    min-width: 150px;
    max-width: 150px;
  }
}


.chat-container {
  min-height: 400px;
}

.chat-avatar {
  width: 40px;
  min-width: 40px;
}


.chat-image-thumb {
  height: 100px;
  max-height: 100px;
  max-width: 200px;
}

.chat-image-preview {
  max-height: 100px;
  max-width: 100px;
}

.messages {
  //    min-height: 200px;
  //@apply flex flex-col justify-end;
}

.message-wrap {
  @apply px-1;
  @apply pb-1;
  display: flex;
}


.message-bubble {
  min-width: 100px;
  @apply px-2;
  border-radius: 7px 7px 7px 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: inline-block;
  position: relative;
  @apply py-1;
}

.message-recipient .message-bubble {
  background-color: #736e6ee8;
}

.message {

}

.message-me {
  margin-left: auto;
}

.message-me .message-bubble {
  border-radius: 7px 7px 0 7px;
}


.message-me .message-bubble:after {
  content: '';
  bottom: 0;
  right: -9px;
  border-width: 0px 10px 9px 0px;
  position: absolute;
  border-color: rgba(0, 0, 0, 0.8) transparent;
}

.message-recipient .message-bubble:after {
  content: '';
  bottom: 0;
  left: -9px;
  border-width: 9px 10px 0px 0px;
  position: absolute;
  border-color: transparent #736e6ee8;
}

.message-info {
  @apply px-2;
  font-size: 0.65em;
  color: #AAAAAACC;
  line-height: 1;

}

.message-me .message-info {
  text-align: right;
}

/** ImageEdit */

.close-button {
  @apply rounded;
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 60;
  display: block;
  line-height: 0.75;
}


.popper {
  width: auto;
  max-width: 320px;
  background-color: rgba(0, 0, 0, 0.8);
  //color: #212121;
  color: white;
  text-align: left;
  padding: 4px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  border: 1px rgba(0, 0, 0, 0.5) solid;
  z-index: 200000;
  -moz-box-shadow: rgb(58, 58, 58) 0 0 6px 0;
  -webkit-box-shadow: rgb(58, 58, 58) 0 0 6px 0;
  box-shadow: rgb(58, 58, 58) 0 0 6px 0;
}

.popper .popper__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.popper[x-placement^='top'] {
  margin-bottom: 5px;
}

.popper[x-placement^='top'] .popper__arrow {
  border-width: 5px 5px 0 5px;
  border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
  bottom: -5px;
  //left: calc(50% - 5px);
  left: 2px !important;
  margin-top: 0;
  margin-bottom: 0;
}

.popper[x-placement^='bottom'] {
  margin-top: 5px;
}

.popper[x-placement^='bottom'] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.popper[x-placement^='right'] {
  margin-left: 5px;
}

.popper[x-placement^='right'] .popper__arrow {
  border-width: 5px 5px 5px 0;
  border-color: transparent rgba(0, 0, 0, 0.5) transparent transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.popper[x-placement^='left'] {
  margin-right: 5px;
}

.popper[x-placement^='left'] .popper__arrow {
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.5);
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

/// **** pagination **** ///

.d-pagination {
  text-align: center;
  font-size: 1.2rem;
  padding: 3px;
  list-style: none;
  color: white;
}

.d-pagination .disabled {
  color: #EEE;
}

.d-pagination .active {
  font-weight: bold;
}

.d-pagination > li, .d-pagination > a, .d-pagination > span {
  border-radius: 2px;
  display: inline-block;
  padding: 0.5em 0.7em;
  margin: 0 0.2em 0.7em 0.2em;
}

.d-pagination a, .d-pagination a:active, .d-pagination a:visited, .d-pagination a:hover {
  text-decoration: none;
  color: white;
}

/// ** radio **//

.form-radio {
  height: 0.813em;
  width: 0.813em;
}

.form-radio-black {
  border: 1px solid gray;
}

.form-radio-black:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='3'/%3E%3C/svg%3E");
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 1px solid black;
}

.form-checkbox {
  height: 0.813em;
  width: 0.813em;
}


