.list-enter-active, .list-leave-active {
    transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
{
    opacity: 0;
    transform: translateY(30px);
}



@keyframes blink {0%{opacity:1;} 50%{opacity:0.5;} 100%{opacity:1;}}

@keyframes heartbeat {
    0% {
        transform: scale(.75);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(.75);
    }
    100% {
        transform: scale(.75);
    }
}
.heartbeat{
    -webkit-animation-name: heartbeat;
    animation-name: heartbeat;
}
