
.responsive-name {
    @apply text-xl text-center font-bold block  no-underline px-4 flex-1;
}

.profile-chat-info {
    @apply flex flex-row items-center px-2 py-1 w-full;
    max-height: 100px;
    text-align: center;
}

.profile-view-info {
    text-align: center;
}

.profile-chat-info .responsive-name {
    @apply text-left text-xl;
}

.profile-chat-info .profile-image-wrapper {
    padding: 2px;
}

.profile-chat-info .pl-image {
    max-height: 45px;
}

.profile-chat-info .onlineIndicator {
    display: none;
}


.profile-view-info .onlineIndicator {
    @apply text-sm;
    top: 5px;
    left: 5px;
    padding: 1px 2px;

}

.profile-view-info .onlineIndicator > span {
    margin-left: -62px;
}

.profile-image-wrapper {
    @apply p-2;
    display: inline-block;
}


.profile-wrapper {
    @apply flex flex-col ;
}

.chatter .profile-wrapper {
    height: 100%;
}

.profile-left {
    text-align: center;
}

.profile-image {
    @apply rounded;
    width: 100%;
}

.big-photo-wrapper {
    width: 90vh;
}

.big-photo {
    width: 100%;
}

.profile-edit-select {
    @apply text-black w-full p-1 ml-2;
    width: 100px;
}


.pl-image {
    @apply rounded;
    max-height: 210px;
    @apply mx-auto;
}

@screen md {

    .profile-chat-info {
        padding: 0;
        display: block;
    }

    .profile-chat-info .responsive-name {
        @apply text-center;
    }

    .profile-chat-info .profile-image-wrapper {
        @apply p-2;
    }


    .profile-chat-info .pl-image {
        max-height: 210px;
    }

    .profile-chat-info .onlineIndicator {
        display: block;
    }


    .profile-chat-info {
        display: block;
        max-height: unset;
    }

    .profile-wrapper {
        @apply p-2 flex-wrap;
        min-height: 530px;
        flex-direction: row;
    }

    .profile-left {
        text-align: left;
    }

    .pl-image {

    }

    .big-photo-wrapper {
        width: 70vh;
    }

    .pl-image {
        @apply rounded;
        max-height: 210px;
        @apply mx-auto;
    }


}

.big-photo[lazy=loaded] {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

/** Profile edit **/


.profile-photo-edit {
    @apply text-gray-300 text-2xl;
    display: block;
    position: absolute;
    border-radius: 9999px;

    left: 10px;
    top: 0;
    text-shadow: 1px 1px 2px #333;
}
