.logo {
  @apply px-2;

  @apply inline;
  font-size: 36px;
  line-height: 1em;
  //font-family: 'Gabriola', 'Simonetta', cursive;
}

.logo-dot {
  @apply text-red-600;
  line-height: 0;
  font-size: 55px;
}

.menu-button {
  @apply text-3xl inline-block font-bold text-red-600 button-text-shadow-c px-1;
}

.menu-item {
  @apply block no-underline  text-white mb-2 px-1 whitespace-no-wrap;
}

.menu-item:hover {
  @apply underline;
}

.verify-reminder {
  @apply px-12 py-1 w-full text-center date-box leading-none relative flex flex-wrap items-center;
  min-height: 44px;
  margin-bottom: 4px;
}

@screen md {
  .logo {
    font-size: 50px;
  }

  .menu-item {
    @apply text-sm;
  }

  .menu-button {
    @apply text-3xl;
  }

  .menu-button-email {
    font-size: 32px;
    margin-top: -1px;
    line-height: 31px;
  }
}
