.chat-window {
    margin: 0 4px;
    width: 280px;

    flex-direction: column;
}

.cw-header {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: rgba(61, 61, 61, 0.9);
    color: white;

}

.cw-body {

    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding: 4px 4px 0;
    background-color: rgba(61, 61, 61, 0.9);
    color: #EEE;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

